<section *ngIf="_loaded" class="py-5 bg-blue-100">
  <div class="container py-5 bg-blue-100">
    <div class="row">
      <div class="col-lg-8 mb-5 mb-lg-0 bg-blue-100">
        <!-- About-->
        <div class="card border-0 shadow-sm mb-4 mb-lg-5 p-2 p-lg-0 bg-blue-100">
          <div class="card-body p-lg-5 bg-blue-100">
            <h2 class="h3 mb-4">Personal</h2>
            <div class="row col-12">
              <div class="col-4">
                <div id="imagePreview" class="text-left">
	                <img height="150px" width="auto" class="rounded-circle" src="{{_candidate.eciPhotoLink}}"
                    alt="..."> 
                  <div class="font-medium  text-sm text-indigo-600 hover:text-indigo-500">
                    <a href="{{_candidate.officialProfileLink}}" target="_blank">ECI Candidate link</a>
                  </div>
				<div   *ngIf="_candidate.mynetaCandidateUrl && _candidate.mynetaCandidateUrl != 'Coming soon...'"
                    class="mt-1 text-sm">
                  <div class="font-medium text-indigo-600 hover:text-indigo-500" >
                      <a href="{{_candidate.mynetaCandidateUrl}}" target="_blank">MyNeta Candidate link</a>                      
            	  </div>
                  </div>
                  <div  *ngIf="_candidate.mynetaCandidateUrl && _candidate.mynetaCandidateUrl == 'Coming soon...'"
                    class="mt-1 text-sm">
                    <dt>MyNeta Candidate link Coming soon...</dt>
                  </div>
				  <div  *ngIf="!_candidate.mynetaCandidateUrl"
                    class="mt-1 text-sm"
                  >
                    <a
                      href="https://www.myneta.info/LokSabha2019/"
                      target="_blank"
                      class="font-medium text-indigo-600 hover:text-indigo-500"
                      >MyNeta All Candidate link</a
                    >
                  </div>


            	  <!--<div *ngIf="!_candidate.mynetaCandidateUrl" class="font-medium text-indigo-600 hover:text-indigo-500">
            	  	<a href="https://www.myneta.info/LokSabha2019/" target="_blank">MyNeta All Candidate link</a>
            	  </div>-->
					<!--  <dd class="font-medium text-indigo-600 hover:text-indigo-500"  *ngIf="_candidate.facebookId">
						<a href="{{_candidate.facebookId }}" target="_blank"
						  >Facebook link</a
						>
					  </dd>

                          <dd class="font-medium text-indigo-600 hover:text-indigo-500" *ngIf="_candidate.whatsappId">
                            <a href="{{_candidate.whatsappId}}" target="_blank"
                              >WhatsApp link</a
                            >
                          </dd>

                          <dd class="font-medium text-indigo-600 hover:text-indigo-500" *ngIf="_candidate.twitterId">
                            <a href="{{_candidate.twitterId}}" target="_blank"
                              >Twitter link</a
                            >
                          </dd>

                          <dd class="font-medium text-indigo-600 hover:text-indigo-500" *ngIf="_candidate.instagramId">
                            <a href="{{_candidate.instagramId}}" target="_blank"
                              >Instagram link</a
                            >
                          </dd>
						  -->
                </div>
              </div>
              <div class="col-8">
                <div class="text-left" >
					  <dt>Name</dt>
                  <p class="text-gray-900 text-sm text-left">{{_candidate.name | titlecase}}</p>
                </div>
                <div class="text-left" >
					  <dt>Age</dt>
                  <p class="text-gray-900 text-sm">{{_candidate.age}}</p>
                </div>
                <div class="text-left" >
					  <dt>Gender</dt>
                  <p class="text-gray-900 text-sm">{{_candidate.sex}}</p>
                </div>
                <div class="text-left">
                  <dt>Party</dt>
                  <p class="text-gray-900 text-sm">{{_candidate.party}}</p>
                </div>
                <div class="text-left">
                  <dt>EMail</dt>
                  <p class="text-gray-900 text-sm">{{_candidate.email}}</p>
                </div>
                <div class="text-left">
                  <dt>Phone</dt>
                  <p class="text-gray-900 text-sm">{{_candidate.telephone}}</p>
                </div>
                <div class="text-left">
                  <dt>Profession</dt>
                  <p class="text-capitalize text-gray-900 text-sm">{{_candidate.selfProfession | titlecase}}</p>
                </div>

                <div class="text-left">
                  <dt>Spouse Profession</dt>
                  <p class="text-gray-900 text-sm">{{_candidate.spouseProfession | titlecase}}</p>
                </div>
                <div class="text-left">
                  <dt>Education</dt>
                  <p class="text-gray-900 text-sm">{{_candidate.highestEducationLevel | titlecase}}</p>
                </div>
				
				<div
                    *ngIf="_candidate.candidateTotalIncome!=-1"
                    class="mt-1 text-sm"
                  >
                        <dt>Income</dt>
                        <dd class="text-gray-900 text-sm">
                          {{
                            _candidate.candidateTotalIncome.toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0
                              }
                            )
                          }}
                        </dd>
						</div>
				        <div
                    *ngIf="_candidate.candidateTotalIncome==-1"
                    class="mt-1 text-sm"
                  >
                        <dt>Income</dt>
                        <dd class="text-gray-900 text-sm">
                         Coming Soon...
                        </dd>
						</div>

				<div
                    *ngIf="_candidate.candidateTotalWealth!=-1"
                    class="mt-1 text-sm"
                  >
                        <dt>Total Wealth</dt>
                        <dd class="text-gray-900 text-sm">
                          {{
                            _candidate.candidateTotalWealth.toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0
                              }
                            )
                          }}
                        </dd>
						</div>
				        <div
                    *ngIf="_candidate.candidateTotalWealth==-1"
                    class="mt-1 text-sm"
                  >
                        <dt>Total Wealth</dt>
                        <dd class="text-gray-900 text-sm">
                         Coming Soon...
                        </dd>
						</div>

              </div>
            </div>
          </div>
        </div>


        <div class="card border-0 shadow-sm mb-4 mb-lg-5 p-2 p-lg-0 bg-blue-100">
          <div class="card-body p-lg-5">
            <h4 class="h3 mb-4">Last Income</h4>
			<div   *ngIf="_candidate.mynetaCandidateUrl && _candidate.mynetaCandidateUrl != 'Coming soon...'"
                    class="mt-1 text-sm">

            <h5 *ngIf="_candidate.latestTaxReturn" class="h5 mb-6">{{_candidate.latestTaxReturn.self.year}}</h5>
            <div *ngIf="_candidate.latestTaxReturn" class="row col-12">
              <div class="col-6">
                <div class="text-left">
                  <dt>Self</dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.latestTaxReturn.self.amount != ''">
                    {{ formatAmount(_candidate.latestTaxReturn.self.amount).toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0}) }}</p>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.latestTaxReturn.self.amount == ''">NA</p>
                </div>
                <div *ngIf="_candidate.latestTaxReturn.spouse" class="text-left">
                  <dt>Spouse</dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.latestTaxReturn.spouse.amount != ''">
                    {{ formatAmount(_candidate.latestTaxReturn.spouse.amount).toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0}) }}</p>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.latestTaxReturn.spouse.amount == ''">NA</p>
                </div>
              </div>
              <div *ngIf="_candidate.latestTaxReturn.dependents" class="col-6">
                <div *ngFor="let dependent of _candidate.latestTaxReturn.dependents" class="text-left">
                  <dt>{{dependent.relation}}</dt>
                  <p class="text-gray-900 text-sm">{{ formatAmount(dependent.amount).toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0}) }}</p>
                </div>
              </div>
              <div class="row col-12">
                <div class="col-6">
                  <div class="text-left">
                    <dt>Total Income</dt>
                    <p class="text-gray-900 text-sm">{{_candidate.candidateTotalIncome.toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0}) }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!_candidate.latestTaxReturn" class="row col-12">
              <p class="text-gray-900 text-sm">No income reported</p>
            </div>
            </div>
			  <div  *ngIf="_candidate.mynetaCandidateUrl && _candidate.mynetaCandidateUrl == 'Coming soon...'"
				class="mt-1 text-sm">
				<dt>Income Coming soon...</dt>
			  </div>


          </div>
        </div>

        <!-- Video-->
        <div class="card border-0 shadow-sm mb-4 mb-lg-5 p-2 p-lg-0 bg-blue-100">
          <div class="card-body p-lg-5">
            <h4 class="h3 mb-4">Assets</h4>
			<div   *ngIf="_candidate.mynetaCandidateUrl && _candidate.mynetaCandidateUrl != 'Coming soon...'"
                    class="mt-1 text-sm">
            <h5 class="h5 mb-6">Movable Assets</h5>
            <div class="row col-12">
              <div class="col-6">

                <div class="text-left">
                  <dt>Self</dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.assets.movableSelf">
                    {{ formatAmount(_candidate.assets.movableSelf.amount).toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}</p>
                  <p class="text-gray-900 text-sm" *ngIf="!_candidate.assets.movableSelf">
                    No movable assets reported. Please verify at myneta page</p>
                </div>
                <div class="text-left">
                  <dt>Spouse</dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.assets.movableSpouse">
                    {{ formatAmount(_candidate.assets.movableSpouse.amount).toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}</p>
                  <p class="text-gray-900 text-sm" *ngIf="!_candidate.assets.movableSpouse">
                    No movable assets reported. Please verify at myneta page</p>
                </div>
              </div>
              <div class="col-6">
                 <div *ngFor="let dependent of _candidate.assets.movableDependents" class="text-left">
                  <dt>{{dependent.relation | titlecase }}</dt>
                  <p class="text-gray-900 text-sm">{{ formatAmount(dependent.amount).toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}</p>
                 </div>
              </div>
              <div class="row col-12">
                <div class="col-6">
                  <div class="text-left">
                    <dt>Total Movable Assets</dt>
                    <p class="text-gray-900 text-sm">{{ _candidate.assets.movableTotal.toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}</p>
                  </div>
                </div>
              </div>
            </div>

           

            <h5 class="h5 mb-6">Immovable Assets</h5>
            <div class="row col-12">
              <div class="col-6">
                <div class="text-left">
                  <dt>Self</dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.assets.immovableSelf">
                    {{  formatAmount(_candidate.assets.immovableSelf.amount).toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}</p>
                  <p class="text-gray-900 text-sm" *ngIf="!_candidate.assets.immovableSelf">
                    No movable assets reported. Please verify at myneta page</p>
                </div>
                <div class="text-left">
                  <dt>Spouse</dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.assets.immovableSpouse">
                    {{ formatAmount(_candidate.assets.immovableSpouse.amount).toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}</p>
                  <p class="text-gray-900 text-sm" *ngIf="!_candidate.assets.immovableSpouse">
                    No movable assets reported. Please verify at myneta page</p>
                </div>
              </div>
              <div class="col-6">
                <div *ngFor="let dependent of _candidate.assets.immovableDependents" class="text-left">
                  <dt>{{dependent.relation | titlecase}}</dt>
                  <p class="text-gray-900 text-sm">{{ formatAmount(dependent.amount).toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}</p>
                </div>
              </div>
              <div class="row col-12">
                <div class="col-6">
                  <div class="text-left">
                    <dt>Total Immovable Assets</dt>
                    <p class="text-gray-900 text-sm">{{ _candidate.assets.immovableTotal.toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}</p>
                  </div>
                </div>
              </div>
            </div>

            <h5 class="h5 mb-6">Total Assets</h5>
            <div class="row col-12">
              <p class="text-gray-900 text-sm">{{ _candidate.assetsSummary.toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}</p>
            </div>
            
          </div>
			  <div  *ngIf="_candidate.mynetaCandidateUrl && _candidate.mynetaCandidateUrl == 'Coming soon...'"
				class="mt-1 text-sm">
				<dt>Assets Coming soon...</dt>
			  </div>
        </div>

		</div>

        <div class="card border-0 shadow-sm mb-4 mb-lg-5 p-2 p-lg-0 bg-blue-100">
          <div class="card-body p-lg-5">
            <h4 class="h3 mb-4">Liabilities</h4>
           <div   *ngIf="_candidate.mynetaCandidateUrl && _candidate.mynetaCandidateUrl != 'Coming soon...'"
                    class="mt-1 text-sm">
            <div *ngIf="_candidate.liabilities" class="row col-12">
               
              <div class="col-6">
                <div *ngIf="_candidate.liabilities.self" class="text-left">
                  <dt>Self</dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.liabilities.self.amount != ''">
                    {{ formatAmount(_candidate.liabilities.self.amount).toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}</p>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.liabilities.self.amount == ''">
                    NA</p>
                </div>
                <div  *ngIf="_candidate.liabilities.spouse" class="text-left">
                  <dt>Spouse</dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.liabilities.spouse.amount != ''">
                    {{ formatAmount(_candidate.liabilities.spouse.amount).toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}</p>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.liabilities.spouse.amount == ''">
                    NA</p>
                </div>
              </div>
              <div  *ngIf="_candidate.liabilities.dependents"  class="col-6">
                <div *ngFor="let dependent of _candidate.liabilities.dependents" class="text-left">
                  <dt>{{dependent.relation | titlecase}}</dt>
                  <p class="text-gray-900 text-sm">{{ formatAmount(dependent.amount).toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}</p>
                </div>
              </div>
              <div class="row col-12">
                <div class="col-6">
                  <div class="text-left">
                    <dt>Total Liabilities</dt>
                    <p class="text-gray-900 text-sm">{{_candidate.liabilities.candidateTotalLiabilityString.toLocaleString('en-IN' , { style: 'currency', currency: 'INR', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="!_candidate.liabilities">
              <p class="text-gray-900 text-sm">No liabilities reported</p>
            </div>
			 </div>
			  <div  *ngIf="_candidate.mynetaCandidateUrl && _candidate.mynetaCandidateUrl == 'Coming soon...'"
				class="mt-1 text-sm">
				<dt>Liabilities Coming soon...</dt>
			  </div>
          </div>
        </div>
        
       <div class="card border-0 shadow-sm mb-4 mb-lg-5 p-2 p-lg-0 bg-blue-100">
          <div class="card-body p-lg-5">
            <h4 class="h3 mb-4">Pending Cases</h4>
           <div   *ngIf="_candidate.mynetaCandidateUrl && _candidate.mynetaCandidateUrl != 'Coming soon...'"
                  class="mt-1 text-sm">
            <ng-container *ngIf="_candidate.pendingCase.firNumber">
            <div class="row col-12">
				<p class="text-gray-900 text-sm" *ngIf="!_candidate.assets.movableSelf">
                    Please verify at myneta page for more cases</p>
              <div class="col-6">
                <div class="text-left">
                  <dt>FIR Numbers</dt>
                  <p class="text-gray-900 text-sm">{{_candidate.pendingCase.firNumber}}</p>
                </div>
                <div class="text-left">
                  <dt>Police station Names </dt>
                  <p class="text-gray-900 text-sm">{{_candidate.pendingCase.policeStationName}}</p>
                </div>
              </div>
              <div class="col-6">
                <div class="text-left">
                  <dt>Section Details </dt>
                  <p class="text-gray-900 text-sm">{{_candidate.pendingCase.section}}</p>
                </div>
                <div class="text-left">
                  <dt>Description </dt>
                  <p class="text-gray-900 text-sm">{{_candidate.pendingCase.briefDescription}}</p>
                </div>

              </div>
            </div>
            <div class="row col-12">
              <div class="col-6">
                <div class="text-left">
                  <dt>Section Details </dt>
                  <p class="text-gray-900 text-sm">{{_candidate.pendingCase.section}}</p>
                </div>
                <div class="text-left">
                  <dt>Case Number </dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.pendingCase.caseNumber != ''">{{_candidate.pendingCase.caseNumber}}</p>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.pendingCase.caseNumber == ''">NA</p>
                </div>
                <div class="text-left">
                  <dt>Court Name </dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.pendingCase.courtName != ''">{{_candidate.pendingCase.courtName}}</p>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.pendingCase.courtName == ''">NA</p>
                </div>
                
              </div>
              <div class="col-6">
                <div class="text-left">
                  <dt>Charges Handed </dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.pendingCase.chargesFramed">Yes</p>
                  <p class="text-gray-900 text-sm" *ngIf="!_candidate.pendingCase.chargesFramed">No</p>
                </div>
                <div class="text-left">
                  <dt>Date charges Handed </dt>
                  <p class="text-gray-900 text-sm">{{_candidate.pendingCase.dateOfChargesFramed}}</p>
                </div>
                <div class="text-left">
                  <dt>Apeal Against Proccedings</dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.pendingCase.appealAgainstProceedings">Yes</p>
                  <p class="text-gray-900 text-sm" *ngIf="!_candidate.pendingCase.appealAgainstProceedings">No</p>
                </div>
                <div class="text-left">
                  <dt>Appealed Against proceedings details</dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.pendingCase.appealAgainstProceedingsDetails != ''">{{_candidate.pendingCase.appealAgainstProceedingsDetails}}</p>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.pendingCase.appealAgainstProceedingsDetails == ''">NA</p>
                </div>
              </div>
            </div>
			Please check <a href="{{_candidate.mynetaCandidateUrl}}" class="font-medium text-indigo-600 hover:text-indigo-500" target="_blank">MyNeta Candidate link</a>                      
            	  for more details.
            </ng-container>
            <div *ngIf="!_candidate.pendingCase.firNumber">
              <p class="text-gray-900 text-sm">No pending cases reported</p>
            </div>
			 </div>
			  <div  *ngIf="_candidate.mynetaCandidateUrl && _candidate.mynetaCandidateUrl == 'Coming soon...'"
				class="mt-1 text-sm">
				<dt>Pending cases Coming soon...</dt>
			  </div>
          </div>
        </div>

        <div class="card border-0 shadow-sm mb-4 mb-lg-5 p-2 p-lg-0 bg-blue-100">
          <div class="card-body p-lg-5">
            <h4 class="h3 mb-4">Convicted Cases</h4>
			<div   *ngIf="_candidate.mynetaCandidateUrl && _candidate.mynetaCandidateUrl != 'Coming soon...'"
                  class="mt-1 text-sm">
            <ng-container *ngIf="_candidate.convictedCase.firNumber">
            <div class="row col-12">
            	<p class="text-gray-900 text-sm" *ngIf="!_candidate.assets.movableSelf">
                    Please verify at myneta page for more cases</p>
              <div class="col-6">
                <div class="text-left">
                  <dt>Section Details </dt>
                  <p class="text-gray-900 text-sm">{{_candidate.convictedCase.section}}</p>
                </div>
                <div class="text-left">
                  <dt>Description </dt>
                  <p class="text-gray-900 text-sm">{{_candidate.convictedCase.briefDescription}}</p>
                </div>
              </div>
              <div class="col-6">
                <div class="text-left">
                  <dt>Charges Handed </dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.convictedCase.chargesFramed">Yes</p>
                  <p class="text-gray-900 text-sm" *ngIf="!_candidate.convictedCase.chargesFramed">No</p>
                </div>
                <div class="text-left">
                  <dt>Conviction Dates</dt>
                  <p class="text-gray-900 text-sm">{{_candidate.convictedCase.convictionOrderDate}}</p>
                </div>
                <div class="text-left">
                  <dt>Apeal Against Proccedings</dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.convictedCase.appealAgainstProceedings">Yes</p>
                  <p class="text-gray-900 text-sm" *ngIf="!_candidate.convictedCase.appealAgainstProceedings">No</p>
                </div>
                <div class="text-left">
                  <dt>Appealed Against Punishment</dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.convictedCase.appealAgainstPunishment">Yes</p>
                  <p class="text-gray-900 text-sm" *ngIf="!_candidate.convictedCase.appealAgainstPunishment">No</p>
                </div>
               
              </div>
            </div>
            <div class="row col-12">
              <div class="col-6">
                <div class="text-left">
                  <dt>Case Number </dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.convictedCase.caseNumber != ''">{{_candidate.convictedCase.caseNumber}}</p>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.convictedCase.caseNumber == ''">NA</p>
                </div>
                <div class="text-left">
                  <dt>Court Name </dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.convictedCase.courtName != ''">{{_candidate.convictedCase.courtName}}</p>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.convictedCase.courtName == ''">NA</p>
                </div>
                
              </div>
              <div class="col-6">
                <div class="text-left">
                  <dt>Appealed Against Punishment Detail</dt>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.convictedCase.appealAgainstPunishmentDetails != ''">{{ _candidate.convictedCase.appealAgainstPunishmentDetails }}</p>
                  <p class="text-gray-900 text-sm" *ngIf="_candidate.convictedCase.appealAgainstPunishmentDetails == ''">NA</p>
                </div>
              </div>
            </div>
			Please check <a href="{{_candidate.mynetaCandidateUrl}}" class="font-medium text-indigo-600 hover:text-indigo-500" target="_blank">MyNeta Candidate link</a>                      
            	  for more details.
</ng-container>
            <div *ngIf="!_candidate.convictedCase.firNumber">
              <p class="text-gray-900 text-sm">No Convicted cases reported</p>
            </div>
			 </div>
			  <div  *ngIf="_candidate.mynetaCandidateUrl && _candidate.mynetaCandidateUrl == 'Coming soon...'"
				class="mt-1 text-sm">
				<dt>Convicted cases Coming soon...</dt>
			  </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
