<footer class=" bg-blue-500 text-white">
  <div class="container py-1">
    <div class="row py-2">
      <div class="col-md-2 col-sm-12 mb-3 mb-md-0">
        <div class="d-flex align-items-center mb-3">
          <img src="img/logo-footer.svg" alt="" width="30">
          <span class="font-bold text-2xl ml-2">s/Elect</span>
        </div>
        <p class="text-sm italic mb-3">Your next 5 years in 5 minutes.</p>
      </div>
      <div class="col-md-8 col-sm-6 mb-3 mb-md-0">
        <!--<h6 class="pt-2 text-white">Useful links</h6>-->
        <div class="d-flex flex-wrap">
          <ul class="list-unstyled text-cyan-900 text-md">
			<div class="font-bold" >About this app:</div> Elections every where including India, favors those who have power and money. Especially in India, where elections are contested in a multi-party system, only candidates of the big parties are known to most of the voters. They also spend a lot of money to publicize themselves to get more votes. Due to this, every candidate who end up winning, at some point have to compromise their values and adjust to the compulsions, conditions of those who have money and power. And those who have good intentions, not having the means to get the reach, wont get elected. So at the end, whoever wins, will not deliver 100% of what is needed. Through this app, I want to provide a level playing field for everyone, not even to, but specifically to independent candidates who wants to make a difference in the people around their lives by them contesting. 
				I hope many of these less resourceful candidates, gets into the limelight and win an election! Fingers crossed!
          </ul>
          <ul class="list-unstyled text-yellow-900 text-md">
		<div class="font-bold" >About me:</div> I am an Indian Tamil software engineer trying to apply my skills and potential to change the Indian socio-economic-political scenes in any way I can. I have other ideas also to this effect. Please reach out to me at moreanniyan@gmail.com if you want to join with me, or if you want me to join you and your team.</ul>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-gray-700 py-4">
    <div class="container">
      <div class="row text-center">
        <div class="col-md-6 text-lg-left mb-2 mb-md-0">
          <p class="mb-0 text-sm">&copy; 2024 All rights reserved.</p>
        </div>
        <div class="col-md-6 col-sm-6 text-md-right">
          <!-- <p class="mb-0 text-muted mb-0 text-small">Template designed by <a class="text-blue-500" href="https://bootstraptemple.com/p/listings">Bootstrap Temple</a>.</p> -->
          <!-- If you want to remove the backlink, please purchase the Attribution-Free License.-->
        </div>
      </div>
    </div>
  </div>
</footer>