import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../service/data.service';
import { Constituency, Election, Item } from '../types/election';
import * as  _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { Candidate } from '../types/candidate';
import { Case, Filter, Sex, TaxFilled } from '../types/filters';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './list.component.html'
})
export class ListComponent implements OnInit {
 

  private _election: Array<Election> = new Array<Election>();
  public _electionList: Array<Item> = new Array<Item>();
  public _electionTypeList: Array<Item> = new Array<Item>();
  public _stateList: Array<Item> = new Array<Item>();
  public _districtList: Array<Item> = new Array<Item>();
  public _constituencyList: Array<Item> = new Array<Item>();
  public _candidateList: Array<Candidate> = new Array<Candidate>();
  public _filterCandidateList: Array<Candidate> = new Array<Candidate>();
  public _filters: Filter = new Filter();
  public _availableSex: Array<string> = new Array<string>();
  public _availableTaxFilled: Array<string> = new Array<string>();
  public _availableCase: Array<string> = new Array<string>();


  public candidatesWithCases: Array<Candidate> = new Array<Candidate>();
  public womenCandidates: Array<Candidate> = new Array<Candidate>();
  public wealthyCandidates: Array<Candidate> = new Array<Candidate>();
  public graduateCandidates: Array<Candidate> = new Array<Candidate>();
  public candidatesWOPAN: Array<Candidate> = new Array<Candidate>();

  public _candidatesWithCases: number = 0;
  public _womenCandidates: number = 0;
  public _wealthyCandidates: number = 0;
  public _graduateCandidates: number = 0;
  public _candidatesWOPAN: number = 0;

  private _selectedElection: string = '';
  public _selectedLanguage: string = '';
  public _languageLinkPart: string = '';
  public _languageLinkText: string = '';
  public languageLinkTextMap: Map<string, string> = new Map([
        ["Assamese", "অসমীয়াৰ বাবে ইয়াত ক্লিক কৰক"],
        ["Bengali", "বাংলার জন্য এখানে ক্লিক করুন"],
		["Bhojpuri", "ভোজপুরি জন্য এখানে ক্লিক করুন"],
        ["Dogri", "डोगरी लेई इत्थें क्लिक करो"],
		["English", "Click here for English"],
        ["Gujarati", "ગુજરાતી માટે અહીં ક્લિક કરો"],
        ["Hindi", "हिंदी के लिए यहां क्लिक करें"],
        ["Kannada", "ಕನ್ನಡಕ್ಕಾಗಿ ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ"],
		["Konkani", "कोंकणी खातीर हांगा क्लिक करात"],
        ["Maithili", "मैथिली के लेल एतय क्लिक करू"],
		["Malayalam", "മലയാളത്തിന് ഇവിടെ ക്ലിക്ക് ചെയ്യുക"],
        ["Marathi", "मराठीसाठी येथे क्लिक करा"],
		["Meitei", "Click here for Meitei/Manipuri"],
        ["Nepali", "नेपालीको लागि यहाँ क्लिक गर्नुहोस्"],
        ["Odia", "ଓଡିଆ ପାଇଁ ଏଠାରେ କ୍ଲିକ୍ କରନ୍ତୁ |"],
		["Punjabi", "ਪੰਜਾਬੀ ਲਈ ਇੱਥੇ ਕਲਿੱਕ ਕਰੋ"],
        ["Sanskrit", "ਸੰਸਕ੍ਰਿਤ ਲਈ ਇੱਥੇ ਕਲਿੱਕ ਕਰੋ"],
		["Sindhi", "سنڌي لاءِ هتي ڪلڪ ڪريو"],
        ["Tamil", "தமிழுக்கு இங்கே கிளிக் செய்யவும்"],
		["Telugu", "తెలుగు కోసం ఇక్కడ క్లిక్ చేయండి"],
        ["Urdu", "اردو کے لیے یہاں کلک کریں۔"]
		]);
  public languageLinkPartMap: Map<string, string> = new Map([
        ["Assamese", "as"],
        ["Bengali", "bn"],
		["Bhojpuri", "bho"],
        ["Dogri", "doi"],
		["English", "en"],
        ["Gujarati", "gu"],
        ["Hindi", "hi"],
        ["Kannada", "kn"],
		["Konkani", "gom"],
        ["Maithili", "mai"],
		["Malayalam", "ml"],
        ["Marathi", "mr"],
		["Meitei", "mni-Mtei"],
        ["Nepali", "ne"],
        ["Odia", "or"],
		["Punjabi", "pa"],
        ["Sanskrit", "sa"],
		["Sindhi", "sd"],
        ["Tamil", "ta"],
		["Telugu", "te"],
        ["Urdu", "ur"]
		]);
  
  public _constituencyType: string = '';
  private _selectedState: string = '';
  private _selectedDistrict: string = '';
  public _selectedConstituency: string = '';
  private _sortOrder: string = 'ascending';
  private _sortColumn: string = 'age';


  constructor(private route: ActivatedRoute,
    private router: Router,
    private _dataService: DataService,
    private _title: Title) {

  }
  ngOnInit(): void {
    this._title.setTitle('s/Elect | Your next 5 years in 5 minutes. ')
    this._dataService.getCurrentElectionByCountry('india').subscribe((response: Array<Election>) => {
      this._electionList = _.sortBy(_.uniqBy(response, 'electionId').map(x => new Item(x.electionName, x.electionId)), 'text');
      this._stateList = _.sortBy(_.uniqBy(_.flatMap(response, 'constituencies'), 'stateId').map(x => new Item(x.stateName, x.stateId)), 'text');
      this._districtList = _.sortBy(_.uniqBy(_.flatMap(response, 'constituencies'), 'districtId').map(x => new Item(x.districtName, x.districtId)), 'text');
      this._constituencyList = _.sortBy(_.uniqBy(_.flatMap(response, 'constituencies'), 'constituencyId').map(x => new Item(x.constituencyName, x.constituencyId)), 'text');
	  this._electionTypeList = _.sortBy(_.uniqBy(response, 'electionId').map(x => new Item(x.electionName, x.constituencyType)), 'text');
      this._election = response;
      this._selectedElection = this._electionList[0].value;
      this._selectedState = this._stateList[0].value;
	  this._constituencyType = this._electionTypeList[0].value;;
      this.loadDistrict();
      this.initFilter();
    });
  }

  initFilter() {
    this._filters.sex = new Sex();
    this._filters.case = new Case();
    this._filters.taxFilled = new TaxFilled();
    this._availableCase = new Array<string>();
    this._availableSex = new Array<string>();
    this._availableTaxFilled = new Array<string>();
    this._availableSex = _.uniqBy(this._candidateList, 'sex').map(x => x.sex.toLowerCase());
    if (this._candidateList.findIndex(x => x.latestTaxReturn != null) > -1) {
      this._availableTaxFilled.push("yes");
    }
    if (this._candidateList.findIndex(x => x.latestTaxReturn == null) > -1) {
      this._availableTaxFilled.push("no");
    }
    if (this._candidateList.findIndex(x => x.pendingCase != null) > -1) {
      this._availableCase.push("pendingCase");
    }
    if (this._candidateList.findIndex(x => x.convictedCase != null) > -1) {
      this._availableCase.push("convictedCase");
    }
  }

  applyFilter() {
    if (this._candidateList.length > 0) {
      this._filterCandidateList = this._candidateList.filter(x =>
        this._filters.sex.male ? x.sex.toLowerCase() == 'male' : true &&
          this._filters.sex.female ? x.sex.toLowerCase() == 'female' : true &&
            this._filters.sex.other ? x.sex.toLowerCase() == 'other' : true &&
              this._filters.taxFilled.yes ? x.latestTaxReturn : true &&
                this._filters.taxFilled.no ? !x.latestTaxReturn : true &&
                  this._filters.case.pendingCase ? x.pendingCase : true &&
                    this._filters.case.convictedCase ? x.convictedCase : true);
    }
  }

  onClickDetail(candidateId: string) {
    this.router.navigateByUrl(`detail/${this._selectedConstituency}/${candidateId}`);
  }

  onElectionSelect(event: any): void {
    this._selectedElection = event.target.value;
    this._stateList = _.sortBy(
      _.uniqBy(
        _.flatMap(
          this._election.filter(x => x.electionId == this._selectedElection), 'constituencies'), 'stateId')
        .map(x => new Item(x.stateName, x.stateId)), 'text');

    this._selectedState = this._stateList[0].value;
    this.loadDistrict();

  }

  onLanguageSelect(event: any): void {
    this._selectedLanguage = event.target.value;
	this._languageLinkPart = this.languageLinkPartMap.get(this._selectedLanguage);
	this._languageLinkText = this.languageLinkTextMap.get(this._selectedLanguage);
  }

  onStateSelect(event: any): void {
    this._selectedState = event.target.value;;
    this.loadDistrict();
  }



  loadDistrict() {
    let result = this._election.filter(x => x.electionId == this._selectedElection);

    this._districtList = _.sortBy(
      _.uniqBy(
        _.flatMap(
          result, 'constituencies'), 'districtId').filter(x => x.stateId == this._selectedState)
        .map(x => new Item(x.districtName, x.districtId)), 'text');

    this._selectedDistrict = this._districtList[0].value;
    this.loadConstituency();

  }

  onDistrictSelect(event: any): void {
    this._selectedDistrict = event.target.value;
    this.loadConstituency();
  }

  loadConstituency() {
    let result = this._election.filter(x => x.electionId == this._selectedElection);
    this._constituencyList = _.sortBy(
      _.uniqBy(
        _.flatMap(
          result, 'constituencies'), 'constituencyId').filter(x => x.districtId == this._selectedDistrict && x.stateId == this._selectedState)
        .map(x => new Item(x.constituencyName, x.constituencyId)), 'text');

    this._selectedConstituency = this._constituencyList[0].value;
    this.loadConstituencyCandidate();
  }

  onConstituencySelect(event: any): void {
    this._selectedConstituency = event.target.value;

    this.loadConstituencyCandidate();
  }

  loadConstituencyCandidate(): void {
    this._dataService.getCandidateByConstituency(this._selectedConstituency).subscribe((response: any) => {
      this._candidateList = response.candidates;
      this._filterCandidateList = response.candidates;
	  this.candidatesWithCases = this._candidateList.filter(x => x.pendingCase != null);
   	  this.womenCandidates = this._candidateList.filter(x => x.sex == 'female');
	  this.wealthyCandidates = this._candidateList.filter(x => x.candidateTotalWealth > 10000000);
	  //this.graduateCandidates = this._candidateList.filter(x => x.pendingCase != null);
	  //this.candidatesWOPAN = this._candidateList.filter(x => x. != null);


      this._wealthyCandidates = 0;
      this._graduateCandidates = 0;
	  this._candidatesWOPAN = 0;

      this.initFilter();
      this.applySort();
    }, (error: HttpErrorResponse) => {
      if (error.status == 404) {
        this._candidateList = new Array<Candidate>();
        this._filterCandidateList = new Array<Candidate>();
        this.initFilter();
      }
    });
  }

  onSortOrder(event: any): void {
    this._sortOrder = event.target.value;
    this.applySort();
  }

  onSortColumn(event: any): void {
    this._sortColumn = event.target.value;
    this.applySort()
  }

  applySort(): void {
    if (this._sortOrder == 'ascending') {
      this._filterCandidateList = _.sortBy(this._filterCandidateList, this._sortColumn)
    } else {
      this._filterCandidateList = _.sortBy(this._filterCandidateList, this._sortColumn).reverse();
    }
  }



}

